ion-modal#expiration-modal-desktop {
  --height: 956px;
  --max-height: 95vh;
  ion-slides#slides-desktop {
    height: 400px;
    max-height: calc(100vh - 444px);
  }
}
ion-modal#expiration-modal-mobile {
  ion-content {
    --background: #f8f8f8;
    ion-slides#slides-mobile {
      height: 425px;
      max-height: calc(100vh - 320px);
    }
  }
}
