@import "../../../../styles/palette";

.close-account {
  &-modal > modal-wrapper {
    height: 900px !important;
  }
  &-margin > * {
    margin: 20px;
  }
  &-list {
    background-color: #f8f8f8;
  }
  &-item {
    padding-top: 10px;
    margin-bottom: 20px;
  }
  &-explain {
    margin: 5px;
  }
  &-btn-confirm {
    background-color: #ef0033;
    color: #f8f8f8;
    border-radius: 8px;
  }
  &-benefit-icon {
    margin-left: 0px;
    min-width: 22px;
    margin-right: 10px;
  }
  &-balance-item {
    margin-right: 40px;
    margin-left: 5px;
  }
  &-balance-footnote > * {
    margin-top: 60px;
  }
  &-footer {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    background-color: #f8f8f8;
  }
  &-closed-footer {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
  }
  &-game-img {
    width: 57%;
    max-width: 450px;
  }
  &-faded-label {
    color: rgb(85, 85, 85);
  }
  &-faq-margin {
    margin-left: 10px;
  }
}

ion-modal#close-account-modal-desktop {
  --height: 956px;
  --max-height: 95vh;
}

ion-modal#close-account-modal-mobile {
  ion-content {
    --background: #f8f8f8;
  }
}
