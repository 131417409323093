/**************** Color pallettes ************/
:root {
  --ion-color-primary: #181818;
  --ion-color-base: var(--ion-color-primary, #181818) !important;
  --ion-color-contrast: #ffffff;
  --ion-color-secondary: #025ed8;
  --ion-color-tertiary: #3a8f9f;
  --ion-card-color: #181818 !important;
}

$brand-base: #181818;
$brand-base-rbg: 129, 105, 250;
$brand-base-text: #7459f7;
$primary: $brand-base;

$primary-dark: #444147;
$primary-light: #eae8ed;
$secondary-light: #ececec;
$tertiary-light: #edeae0;
$primary-blue: #1c7fff;
$secondary-blue: #e3effc;
$spending-celebration: #ece9e0;
$highlight: #6deb86;
$link-blue: #0066ff;

$palette-one: #3a8f9f;

$primary-fade: #716091;

$primary-text: #4a4a4a;
$placeholder-text: #757575;
$accent-text: #025ed8;
$secondary: $accent-text;
$quaternary: #eeeeee;

$warning-text: #ef473a;
$income: #00c164;

$negative-balance-gray: #cccccc;
$border-gray: #e6e6e6;
$positive-balance-green: #00f079;

$yellow: #fcea00;

$white: #ffffff;
$black: #253239;

$text-gray: #818689;

$lighter-gray: #f6f6f6;
$dark-gray: #999999;
$light-gray: #e9e9e9;
$medium-gray: #dddddd;
$gray: $placeholder-text;

$light-blue: #f8f8fb;

$light-black: #333333;

$onboarding-bg: linear-gradient(-180deg, #765cfa 0%, #a74bff 100%);

$progress-fill: #ff6539;
$select-fill: #ff653920;
$select-border: #ed6e47;

.color-white {
  background-color: white;
}

.brand-base {
  color: $brand-base !important;
  &::before {
    color: $brand-base !important;
  }
}

.brand-base-text {
  color: $brand-base-text !important;
  &::before {
    color: $brand-base-text !important;
  }
}

.white {
  color: $white !important;
  &::before {
    color: $white !important;
  }
}

.primary-text {
  color: $primary-text !important;
  &::before {
    color: $primary-text !important;
  }
}

.accent-text {
  color: $accent-text !important;
  &::before {
    color: $accent-text !important;
  }
}

.placeholder-text {
  color: $placeholder-text !important;
  &::before {
    color: $placeholder-text !important;
  }
}

.gray {
  background-color: gray;
}

.color-blue-secondary {
  background-color: $secondary-blue;
}

.color-gray-light {
  background-color: $light-gray;
}

.color-primary-light {
  background-color: $primary-light;
}

button.btn.btn-primary {
  background: $brand-base;
  border-color: $brand-base;
}

.color-border-primary-top {
  border-top: 1px solid $light-gray;
}

button.btn.btn-secondary {
  background: $secondary;
  border-color: $secondary;
}

.money-out {
  color: $black !important;
}
.money-in {
  color: $income !important;
}
