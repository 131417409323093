:root {
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-lg: 32px;
  --spacing-xl: 160px;
  --spacing-ionic: 16px;
  --spacing-lg: 32px;
}

.fy-width-xs {
  width: var(--spacing-xs);
}

.fy-width-sm {
  width: var(--spacing-sm);
}

.fy-width-xl {
  width: var(--spacing-xl);
}

.fy-width-ionic {
  width: var(--spacing-ionic);
}

.fy-padding-vertical-xs {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}

.fy-padding-top-xs {
  padding-top: var(--spacing-xs);
}

.fy-padding-top-md {
  padding-top: var(--spacing-md);
}

.fy-padding-bottom-xs {
  padding-bottom: var(--spacing-xs);
}

.fy-padding-vertical-lg {
  padding-top: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
}

.fy-padding-horizontal {
  padding-left: auto;
  padding-right: auto;
}

.fy-padding-vertical-sm {
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
}

.fy-padding-vertical-md {
  padding-top: var(--spacing-md);
  padding-bottom: var(--spacing-md);
}

.fy-margin-horizontal-sm {
  margin-left: var(--spacing-sm);
  margin-right: var(--spacing-sm);
}

.fy-margin-horizontal-md {
  margin-left: var(--spacing-ionic);
  margin-right: var(--spacing-ionic);
}

.fy-padding-top-sm {
  padding-top: var(--spacing-sm);
}

.fy-padding-bottom-sm {
  padding-bottom: var(--spacing-sm);
}

.fy-margin-vertical {
  margin-top: auto;
  margin-bottom: auto;
}

.fy-margin-vertical-sm {
  margin-top: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
}

.fy-margin-vertical-md {
  margin-top: var(--spacing-ionic);
  margin-bottom: var(--spacing-ionic);
}

.fy-margin-horizontal {
  margin-left: auto;
  margin-right: auto;
}

.fy-padding-vertical-md {
  padding-bottom: var(--spacing-ionic);
  padding-top: var(--spacing-ionic);
}

.display-block {
  display: block;
}

.h-3 {
  height: 24px;
}

.w-50 {
  width: 50%;
}

.w-card {
  width: 360px;
}
